import classNames from 'classnames/bind';
import React from 'react';

import { Image } from '@components/Image/Image';

import styles from './Logo.module.scss';

export interface ILogo {
  src: string;
  width: number;
  height: number;
  name: string;
  scale?: number;
  priority?: boolean;
}

interface IProps extends ILogo {
  className?: string;
}

const cx = classNames.bind(styles);

export const Logo: React.FC<IProps> = ({
  src,
  name,
  width,
  height,
  className,
  scale = 1,
  priority,
}) => (
  <Image
    alt={`${name} logo`}
    className={cx('logo', className)}
    height={height * scale}
    priority={priority}
    src={src}
    width={width * scale}
  />
);
