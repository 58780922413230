import React from 'react';

import { Button } from '@components/Button/Button';
import { Col } from '@components/Layout/Col/Col';
import { Row } from '@components/Layout/Row/Row';
import { Link } from '@components/Link/Link';
import { trackClickEvent } from '@gaEvents';
import { convertTelToHref, sales, support } from '@siteData';

import styles from './ContactLinks.module.scss';
import EnvelopeIcon from '@icons/envelope.svg';
import FacebookIcon from '@icons/facebook.svg';
import LinkedinIcon from '@icons/linkedin.svg';
import PhoneIcon from '@icons/phone.svg';
import TwitterIcon from '@icons/twitterX.svg';

const socialLinks = [
  {
    href: 'https://www.facebook.com/pages/CDN77com/187713678001295',
    title: 'Facebook',
    icon: <FacebookIcon />,
  },
  {
    href: 'https://twitter.com/CDN77com',
    title: 'Twitter',
    icon: <TwitterIcon />,
  },
  {
    href: 'https://www.linkedin.com/company/cdn77-com/',
    title: 'LinkedIn',
    icon: <LinkedinIcon />,
  },
];

const contactLinks = [
  {
    href: convertTelToHref(sales.phone.uk),
    title: sales.phone.uk,
    icon: <PhoneIcon />,
  },
  {
    href: `mailto:${support.email}`,
    title: support.email,
    icon: <EnvelopeIcon />,
  },
];

export const ContactLinks: React.FC = () => (
  <Row gapY="none">
    {contactLinks.map(({ href, title, icon }) => (
      <div key={title} className={styles.linkWrapper}>
        <Button
          className={styles.link}
          href={href}
          icon={icon}
          iconPosition="before"
          iconSize={16}
          spacing="xl"
          variant="inherit"
          external
          onClick={() => {
            trackClickEvent(title, 'contact', 'link');
          }}
        >
          {title}
        </Button>
      </div>
    ))}
    <Col gapY="none" lg={12} md="auto">
      <Row className={styles.socialWrapper} gapY="none">
        {socialLinks.map(({ href, title, icon }) => (
          <Col key={title} gapY="none" xs="auto">
            <Link className={styles.icon} href={href} label={title} external>
              {icon}
            </Link>
          </Col>
        ))}
      </Row>
    </Col>
  </Row>
);
