import { useRouter } from 'next/router';
import React from 'react';

import { useQuery } from '@hooks/useQuery';
import { endPoints } from '@routes';

type Response = { ip: string };

export const useInitialDataQuery = () => {
  const router = useRouter();

  const query = useQuery<Response>({
    method: 'GET',
    url: endPoints.userIp,
  });

  React.useEffect(() => {
    void query.refetch();
  }, [router.pathname]);

  return query;
};
