import classNames from 'classnames/bind';
import React from 'react';

import { Container } from '@components/Layout/Container/Container';
import { Padding } from '@components/Padding/Padding';
import type { Size } from '@components/Padding/usePaddingContext';

import styles from './Section.module.scss';

interface IProps {
  tag?: 'section' | 'div' | 'header' | 'main';
  id?: string;
  className?: string;
  theme?: 'default' | 'tiles' | 'dark' | 'gray';
  fadeOutBackground?: boolean;
  borderRadius?: 'sharp' | 'rounded';
  padding?:
    | Size
    | {
        top?: Size;
        bottom?: Size;
      };
  isPulled?: boolean;
  withContainer?: boolean;
  overflow?: 'auto' | 'hidden';
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Section: React.FC<IProps> = ({
  tag,
  id,
  className,
  theme,
  fadeOutBackground = false,
  padding = 120,
  borderRadius = 'sharp',
  isPulled = false,
  withContainer = !isPulled,
  overflow = 'auto',
  children,
}) => (
  <Padding
    className={cx('wrapper', theme, borderRadius, `overflow-${overflow}`, className, {
      isPulled,
      fadeOutBackground,
    })}
    id={id}
    size={typeof padding === 'number' ? { y: padding, x: 0 } : { ...padding, x: 0 }}
    tag={tag}
  >
    {withContainer ? <Container>{children}</Container> : children}
  </Padding>
);
