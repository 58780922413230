/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import classNames from 'classnames/bind';
import React from 'react';

import styles from './Image.module.scss';

type ISrc = string | IImage;

export interface IImage {
  srcSet: string;
  images: {
    path: string;
    width: number;
    height: number;
  }[];
  src: string;
  toString: () => string;
  placeholder?: string;
  width: number;
  height: number;
}

export interface IImageProps {
  src: ISrc;
  style?: React.CSSProperties;
  width: number;
  height: number;
  offset?: number;
  theme?: 'default' | 'rounded' | 'shadow';
  alt: string;
  priority?: boolean;
  className?: string;
}

const cx = classNames.bind(styles);

export const Image: React.FC<IImageProps> = ({
  src,
  width,
  height,
  theme = 'default',
  alt = '',
  style,
  priority = false,
  className,
}) => (
  <img
    alt={alt}
    className={cx('image', theme, className)}
    decoding="async"
    // experimental feature improving image loading and lighthouse score
    // @ts-ignore
    fetchpriority={priority ? 'high' : undefined}
    height={height}
    loading={priority ? undefined : 'lazy'}
    src={typeof src === 'string' ? src : src.src}
    srcSet={typeof src !== 'string' && 'srcSet' in src ? src.srcSet : undefined}
    style={style}
    width={width}
  />
);
