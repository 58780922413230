import { useRouter } from 'next/router';
import React from 'react';

import type { IMainLink } from '@components/Nav/navigationData';

export interface IUseMenuItem {
  item: IMainLink;
  isMobileMenu?: boolean;
  onCloseMenu: () => void;
}

export const useMenuItem = ({ item, isMobileMenu, onCloseMenu }: IUseMenuItem) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = React.useState(false);

  const unsetIsOpenByResolution = () => {
    if (isMobileMenu) {
      onCloseMenu();
    } else {
      setIsOpen(false);
    }
  };

  const isActive = React.useMemo(() => {
    if (item.submenu) {
      return (
        (router && item.path === router.pathname) ||
        item.submenu.some(({ path }) => router && router.pathname === path)
      );
    }
    if (item.subpages && router) {
      return item.subpages.includes(router.pathname) || item.path === router.pathname;
    }
    if (router) {
      return item.path === router.pathname;
    }

    return false;
  }, [router]);

  return {
    isOpen,
    isActive,
    toggleIsOpen: () => setIsOpen(!isOpen),
    setIsOpen: () => setIsOpen(true),
    setIsOpenWhenDesktop: !isMobileMenu ? () => setIsOpen(true) : undefined,
    unsetIsOpenWhenDesktop: !isMobileMenu ? () => setIsOpen(false) : undefined,
    unsetIsOpenByResolution,
  };
};
