import classNames from 'classnames/bind';
import React from 'react';

import styles from './HideableMobileSection.module.scss';
import AngleDownIcon from '@icons/angleDown.svg';

export interface IHideableMobileSection {
  title?: string;
  subtitle?: string | JSX.Element;
  keepHeaderOnDesktop?: boolean;
  className?: string;
  theme?: 'primary' | 'secondary' | 'tertiary';
}

interface IProps extends IHideableMobileSection {
  isOpen: boolean;
  onToggleOpen: () => void;
  height: number;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const HideableMobileSection: React.FC<IProps> = ({
  title,
  subtitle,
  keepHeaderOnDesktop,
  isOpen,
  onToggleOpen,
  height,
  className,
  theme = 'primary',
  children,
}) => (
  <div className={cx('container', className, theme)}>
    <button
      className={cx('header', { isOpen, keepHeaderOnDesktop })}
      type="button"
      onClick={onToggleOpen}
    >
      <span className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </span>
      <span className={styles.iconWrapper}>
        <AngleDownIcon className={styles.icon} />
      </span>
    </button>
    <div className={cx('content', { isOpen })} style={{ maxHeight: `${isOpen ? height : 0}px` }}>
      {children}
    </div>
  </div>
);
