import '../../modules.d';
import { PrismicProvider } from '@prismicio/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React from 'react';

import { AnalyticsTracking } from './AnalyticsTracking';
import { ContextProvider } from './ContextProvider';
import { Layout } from '@components/Layout/Layout';
import { LiveChat } from '@containers/LiveChat/LiveChat';
import { ServiceWorker } from '@containers/ServiceWorker/ServiceWorker';
import { routes } from '@routes';

const HEALTH_CHECK_URL = '/health-check';
const PAGES_WITHOUT_LAYOUT = [routes.signup, routes.requestdOffer];

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  );
  const router = useRouter();

  const getLayout = (children: JSX.Element) =>
    PAGES_WITHOUT_LAYOUT.includes(router.pathname) ? children : <Layout>{children}</Layout>;

  if (router.pathname === HEALTH_CHECK_URL) {
    return <Component {...pageProps} />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ServiceWorker />
      <ContextProvider>
        <LiveChat />
        <AnalyticsTracking />
        {getLayout(
          <PrismicProvider>
            <Component {...pageProps} />
          </PrismicProvider>,
        )}
      </ContextProvider>
    </QueryClientProvider>
  );
};

export default App;
