import classNames from 'classnames/bind';
import React from 'react';

import { useMenuItem } from './useMenuItem';
import { Link } from '@components/Link/Link';
import type { IMainLink } from '@components/Nav/navigationData';
import { TextWithIcon } from '@components/TextWithIcon/TextWithIcon';

import styles from './MenuItem.module.scss';
import AngleDownIcon from '@icons/angleDown.svg';
import AngleRightIcon from '@icons/angleRight.svg';

interface IProps {
  item: IMainLink;
  isNavOpen: boolean;
  isScrolled?: boolean;
  isMobileMenu?: boolean;
  onCloseMenu: () => void;
}

const cx = classNames.bind(styles);

export const MenuItem: React.FC<IProps> = ({
  item,
  isNavOpen,
  isMobileMenu,
  isScrolled,
  onCloseMenu,
}) => {
  const {
    isOpen,
    setIsOpenWhenDesktop,
    isActive,
    toggleIsOpen,
    unsetIsOpenByResolution,
    unsetIsOpenWhenDesktop,
  } = useMenuItem({ item, isMobileMenu, onCloseMenu });

  return (
    <div className={cx('wrapper', { isOpen, isNavOpen })}>
      {item.submenu && isMobileMenu ? (
        <button
          className={styles.mainLink}
          data-testid={item.name}
          type="button"
          onClick={toggleIsOpen}
        >
          <span className={styles.titleWrapper} title={item.name}>
            {item.name}
          </span>
          <span className={styles.arrowWrapper}>
            <AngleRightIcon className={styles.arrow} />
          </span>
        </button>
      ) : (
        <Link
          activeClassName={!isMobileMenu ? styles.isActive : undefined}
          className={styles.mainLink}
          external={item.external}
          href={item.path}
          isActive={isActive}
          testId={item.name}
          title={item.name}
          onBlur={unsetIsOpenWhenDesktop}
          onClick={onCloseMenu}
          onFocus={setIsOpenWhenDesktop}
          onMouseEnter={setIsOpenWhenDesktop}
          onMouseLeave={unsetIsOpenWhenDesktop}
        >
          {item.submenu ? (
            <TextWithIcon
              animationClass={styles.indicator}
              icon={<AngleDownIcon />}
              iconSize={10}
              spacing="xs"
            >
              {item.name}
            </TextWithIcon>
          ) : (
            item.name
          )}
        </Link>
      )}
      {item.submenu && (
        <ul
          className={cx('content', { isScrolled })}
          onMouseEnter={setIsOpenWhenDesktop}
          onMouseLeave={unsetIsOpenWhenDesktop}
        >
          {item.submenu.map(({ icon, name, description, path, external }) => (
            <li key={path} className={styles.listItem}>
              <Link
                className={styles.submenuLink}
                external={external}
                href={path}
                isActive={isActive}
                testId={name}
                onBlur={unsetIsOpenWhenDesktop}
                onClick={unsetIsOpenByResolution}
                onFocus={setIsOpenWhenDesktop}
              >
                <span className={styles.iconWrapper}>{icon}</span>
                <span className={styles.sublinkContent}>
                  <span className={styles.text}>{name}</span>
                  <span className={styles.description}>{description}</span>
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
