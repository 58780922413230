import classNames from 'classnames/bind';
import React from 'react';

import styles from './Container.module.scss';

export type ILayoutElements = 'section' | 'article' | 'div' | 'span';

interface IProps {
  tagName?: ILayoutElements;
  className?: string;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Container: React.FC<IProps> = ({ tagName: TagName = 'div', className, children }) => (
  <TagName className={cx('container', className)}>{children}</TagName>
);
