import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import React from 'react';

import { AuthContainer } from '@hooks/authContainer';
import { routes } from '@routes';

const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? '';

export const updateIntercom = () => {
  if (typeof window.Intercom === 'function') {
    window.Intercom('update', {
      // eslint-disable-next-line camelcase
      last_request_at: new Date().getTime() / 1000,
    });
  }
};
export const showLiveChat = () => {
  if (typeof window.Intercom === 'function') {
    window.Intercom('show');
  }
};

export const LiveChat: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const router = useRouter();
  const { authState } = AuthContainer.useContainer();

  const userData = {
    // eslint-disable-next-line camelcase
    app_id: intercomAppId,
    // eslint-disable-next-line camelcase
    user_hash: authState.userHashHmac,
    email: authState.email,
    // eslint-disable-next-line camelcase
    user_id: authState.id,
    // eslint-disable-next-line camelcase
    kox_id: authState.id ? `https://kox.cz/${authState.id}` : undefined,
  };

  Router.events.on('routeChangeComplete', () => updateIntercom());

  const pricingPaths = [routes.pricing];

  const isPricingPage = router && pricingPaths.includes(router.pathname);

  React.useEffect(() => {
    const chatTimeout = setTimeout(() => {
      if (!isPricingPage) {
        setIsVisible(true);
      }
    }, 3000);

    const pricingTimeout = setTimeout(() => {
      if (isPricingPage) {
        setIsVisible(true);
      }
    }, 30000);

    return () => {
      clearTimeout(chatTimeout);
      clearTimeout(pricingTimeout);
    };
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      // We don't know when Intercom intializes, let's wait for it using setInterval
      const intercomBootInterval = setInterval(() => {
        if (typeof window.Intercom === 'function') {
          window.Intercom('boot', userData);
          clearInterval(intercomBootInterval);
        }
      }, 300);
      return () => clearInterval(intercomBootInterval);
    }
    return () => undefined;
  }, [isVisible]);

  if (process.env.NODE_ENV !== 'development' && isVisible) {
    return (
      <Head>
        <script src={`https://widget.intercom.io/widget/${intercomAppId}`} async />
      </Head>
    );
  }

  return null;
};
