import React from 'react';

export const ServiceWorker: React.FC = () => {
  React.useEffect(() => {
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
      window.addEventListener('load', () => {
        if (!navigator.serviceWorker.controller) {
          // The window client isn't currently controlled so it's a new service
          // worker that will activate immediately
          return;
        }

        void navigator.serviceWorker.getRegistrations().then(registrations =>
          registrations.forEach(registration => {
            void registration.unregister();
          }),
        );
      });
    }
  }, []);

  return null;
};
