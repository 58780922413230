import React from 'react';

import { Copyright } from '@components/Footer/Copyright/Copyright';
import { Section } from '@components/Section/Section';

import styles from './SimpleFooter.module.scss';

export const SimpleFooter: React.FC = () => (
  <Section padding={0} tag="div" theme="default">
    <div className={styles.wrapper}>
      <div className={styles.copy}>
        <Copyright />
      </div>
    </div>
  </Section>
);
