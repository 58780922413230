import classNames from 'classnames/bind';
import React from 'react';

import type { ILayoutAlign } from '@components/Layout/Row/Row';

import styles from './TextWithIcon.module.scss';

export type IIconSpacing = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface IIconProperty {
  iconPosition?: 'before' | 'after';
  iconSize?: 10 | 12 | 14 | 16 | 18 | 20 | 26 | 30;
  spacing?: IIconSpacing;
}

interface IProps extends IIconProperty {
  icon: JSX.Element;
  className?: string;
  animationClass?: string;
  noEvent?: boolean;
  verticalAlign?: ILayoutAlign;
  horizontalAlign?: ILayoutAlign;
  children?: string | JSX.Element;
}

const cx = classNames.bind(styles);

export const TextWithIcon: React.FC<IProps> = ({
  children,
  icon,
  spacing = 'md',
  iconPosition = 'after',
  iconSize = 14,
  animationClass,
  className,
  noEvent,
  verticalAlign = 'center',
  horizontalAlign = 'center',
}) => {
  const IconElement = (
    <span
      className={cx('iconWrapper', iconPosition, spacing)}
      style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
    >
      <span className={styles.textForHeight}>|</span>
      <span
        className={cx('iconInsideWrapper', animationClass)}
        style={{ width: iconSize, height: iconSize }}
      >
        {icon}
      </span>
    </span>
  );

  return (
    <span
      className={cx(
        'wrapper',
        className,
        `align-items-${verticalAlign}`,
        `justify-content-${horizontalAlign}`,
        { noEvent },
      )}
    >
      {iconPosition === 'before' && IconElement}
      {children}
      {iconPosition === 'after' && IconElement}
    </span>
  );
};
