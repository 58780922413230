import classNames from 'classnames/bind';
import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import type { PopperOptions } from 'react-popper-tooltip';

import styles from './TooltipBubble.module.scss';

interface ITooltipProps {
  text: React.ReactNode;
  options?: PopperOptions;
  offset?: [number, number];
  size?: 'sm' | 'md';
  className?: string;
  isInteractive?: boolean;
  isVisible?: boolean;
  children: (props: {
    setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    tooltip: JSX.Element | null;
  }) => React.ReactNode;
}

const cx = classNames.bind(styles);

export const TooltipBubble: React.FC<ITooltipProps> = ({
  text,
  className,
  options = { placement: 'bottom-start' },
  offset = [0, 8],
  size = 'md',
  isInteractive = true,
  isVisible,
  children,
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible, update } =
    usePopperTooltip(
      {
        interactive: isInteractive,
        offset,
        visible: isVisible,
      },
      options,
    );

  React.useEffect(() => {
    void update?.();
  }, [children]);

  return (
    <>
      {children({
        setTriggerRef,
        tooltip: visible ? (
          <span
            ref={setTooltipRef}
            {...getTooltipProps({
              className: cx('tooltip', size, className, { isInteractive }),
            })}
          >
            <span {...getArrowProps({ className: cx('arrow', { isInteractive }) })} />
            {text}
          </span>
        ) : null,
      })}
    </>
  );
};
