import classNames from 'classnames/bind';
import React from 'react';
import type { PopperOptions } from 'react-popper-tooltip';

import { TooltipBubble } from './TooltipBubble/TooltipBubble';

import styles from './Tooltip.module.scss';
import InfoIcon from '@icons/info.svg';

interface IProps {
  text?: React.ReactNode;
  size?: 'sm' | 'md';
  options?: PopperOptions;
  offset?: [number, number];
  className?: string;
  tooltipClassName?: string;
  isInteractive?: boolean;
  children?: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Tooltip: React.FC<IProps> = ({
  text,
  size,
  options,
  offset,
  className,
  tooltipClassName,
  isInteractive = true,
  children,
}) => {
  if (text === undefined) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <TooltipBubble
      className={tooltipClassName}
      isInteractive={isInteractive}
      offset={offset}
      options={{ placement: options?.placement ?? `bottom${children ? '' : '-start'}`, ...options }}
      size={size}
      text={text}
    >
      {({ setTriggerRef, tooltip }) => (
        <>
          {tooltip}
          {children ? (
            <span
              ref={setTriggerRef}
              className={cx(className, { isText: typeof children === 'string' })}
            >
              {children}
            </span>
          ) : (
            <button
              ref={setTriggerRef}
              className={cx('trigger', className)}
              tabIndex={-1}
              type="button"
            >
              <InfoIcon className={styles.icon} />
            </button>
          )}
        </>
      )}
    </TooltipBubble>
  );
};
