import classNames from 'classnames/bind';
import React from 'react';

import type { ILayoutElements } from '@components/Layout/Container/Container';

import styles from './Row.module.scss';

export type ILayoutGap = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl';
export type ILayoutAlign = 'start' | 'center' | 'end' | 'between' | 'around' | 'stretch';

interface IProps {
  tagName?: ILayoutElements | 'ul';
  horizontalAlign?: ILayoutAlign;
  verticalAlign?: ILayoutAlign;
  className?: string;
  gap?: ILayoutGap;
  gapX?: ILayoutGap;
  gapY?: ILayoutGap;
  noWrap?: boolean;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Row: React.FC<IProps> = ({
  tagName: TagName = 'div',
  className,
  horizontalAlign = 'left',
  verticalAlign,
  gap,
  gapX = gap ? undefined : 'md',
  gapY = gap ? undefined : 'md',
  noWrap,
  children,
}) => (
  <TagName
    className={cx('row', className, `justify-content-${horizontalAlign}`, {
      noWrap,
      [`gap-${gap}`]: gap,
      [`x-${gapX}`]: gapX,
      [`y-${gapY}`]: gapY,
      [`align-items-${verticalAlign}`]: verticalAlign,
    })}
  >
    {children}
  </TagName>
);
