import { handleMalformedResponse } from './handleMalformedResponse';

export const parseApiResponse = async <T>(response: Response): Promise<T> => {
  try {
    const data = await response.json();

    return data as T;
  } catch (error: any) {
    handleMalformedResponse(response.headers, error);

    throw new Error('Invalid response');
  }
};
