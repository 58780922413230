import React from 'react';

import { Container } from '@components/Layout/Container/Container';
import { Link } from '@components/Link/Link';
import { Logo } from '@components/Logo/Logo';
import { Text } from '@components/Text/Text';
import { routes } from '@routes';
import { cdnStats } from '@siteData';

import styles from './SimpleNav.module.scss';
import logoWhiteCdn77 from '@components/Images/Logos/Light/cdn77.svg';

export const SimpleNav: React.FC = () => (
  <header className={styles.nav}>
    <div className={styles.mainWrapper}>
      <Container>
        <div className={styles.wrapper}>
          <Link className={styles.logo} href={routes.homePage} title="Homepage">
            <Logo height={30} name="CDN77" src={logoWhiteCdn77} width={103} />
          </Link>
          <div className={styles.content}>
            <Text color="white" opacity={70} size={14} tag="p">
              Powered by {cdnStats.capacity} CDN77 Network with private global backbone
            </Text>
          </div>
        </div>
      </Container>
    </div>
  </header>
);
