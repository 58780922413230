import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import React from 'react';

import { Hamburger } from './Hamburger/Hamburger';
import { MenuItem } from './MenuItem/MenuItem';
import { SideLinks } from './SideLinks/SideLinks';
import { TopBar } from './TopBar/TopBar';
import { Link } from '@components/Link/Link';
import { Logo } from '@components/Logo/Logo';
import { links } from '@components/Nav/navigationData';
import { routes } from '@routes';

import styles from './Nav.module.scss';
import logoCdn77 from './cdn77.svg';
import logoWhiteCdn77 from '@components/Images/Logos/Light/cdn77.svg';

export interface IProps {
  isOpen: boolean;
  isScrolled: boolean;
  isMobileMenu: boolean;
  isUserLoggedIn: boolean;
  onToggleMenu: () => void;
  onCloseMenu: () => void;
  onLinkClick: () => void;
}

const cx = classNames.bind(styles);

const pagesWithWhiteNav = [routes.tlsTest];

export const Nav: React.FC<IProps> = ({
  isOpen,
  isScrolled,
  isMobileMenu,
  isUserLoggedIn,
  onToggleMenu,
  onCloseMenu,
}) => {
  const router = useRouter();
  const withWhiteNav = router?.pathname && pagesWithWhiteNav.includes(router.pathname);

  return (
    <>
      <TopBar isVisibleOnMobile={false} onCloseMenu={onCloseMenu} />
      <header
        className={cx('nav', {
          isOpen,
          isScrolled,
          isUserLoggedIn,
        })}
        id="nav"
      >
        <div className={styles.mainWrapper}>
          <div className={styles.mainContainer}>
            <div className={styles.wrapper}>
              <div className={styles.head}>
                <div className={styles.headContainer}>
                  <Link
                    className={styles.logo}
                    href={routes.homePage}
                    title="Homepage"
                    onClick={onCloseMenu}
                  >
                    <Logo
                      height={30}
                      name="CDN77"
                      src={withWhiteNav ? logoWhiteCdn77 : logoCdn77}
                      width={103}
                      priority
                    />
                  </Link>
                  <div className={styles.hamburgerContainer}>
                    <Hamburger isActive={isOpen} onToggleMenu={onToggleMenu} />
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={cx('overlay', { isVisible: isOpen })} onClick={onCloseMenu} />
                <nav className={styles.scrollable}>
                  <TopBar isVisibleOnMobile onCloseMenu={onCloseMenu} />
                  <ul className={styles.linksWrapper}>
                    {links.map(item => (
                      <li key={item.name} className={styles.listItem}>
                        <MenuItem
                          isMobileMenu={isMobileMenu}
                          isNavOpen={isMobileMenu ? isOpen : true}
                          isScrolled={isScrolled}
                          item={item}
                          onCloseMenu={onCloseMenu}
                        />
                      </li>
                    ))}
                  </ul>
                  <div className={styles.bottomContainer}>
                    <SideLinks onCloseMenu={onCloseMenu} />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
