import React from 'react';

import { Button } from '@components/Button/Button';
import { externalRoutes } from '@routes';

import styles from './Copyright.module.scss';

export const Copyright: React.FC = () => (
  <span className={styles.copyright}>
    {new Date().getFullYear()}{' '}
    <Button className="text-regular" href={externalRoutes.datacamp} variant="inherit">
      DataCamp Limited
    </Button>
    . All rights reserved.
  </span>
);
