import cookie from 'cookie';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { createContainer } from 'unstated-next';

export interface IAuthState {
  id: number | null;
  analyticsId: string | null;
  email: string | null;
  userHashHmac: string | null;
  isUserDataLoaded: boolean;
}

interface IUserDataCookie {
  aud: string;
  email: string;
  iat: number;
  iss: string;
  id: number;
  user_hash: string;
}

const USERDATA_COOKIE_NAME = 'customer';
const ANALYTICS_COOKIE_NAME = '_ga';

export const getCookie = (name: string) => cookie.parse(document.cookie)[name];

const useAuthContext = () => {
  const [authState, setAuthState] = React.useState<IAuthState>({
    id: null,
    analyticsId: null,
    email: null,
    userHashHmac: null,
    isUserDataLoaded: false,
  });

  const getAnalyticsCookie = () => {
    const cookie = getCookie(ANALYTICS_COOKIE_NAME);

    if (cookie) {
      const numbers = cookie.split('.');
      return `${numbers[2]}.${numbers[3]}`;
    }

    return null;
  };

  const checkUserCookies = () => {
    const userDataCookie = getCookie(USERDATA_COOKIE_NAME);
    if (userDataCookie) {
      const decodedData = jwtDecode<IUserDataCookie>(userDataCookie);

      setAuthState({
        id: decodedData.id,
        analyticsId: getAnalyticsCookie(),
        email: decodedData.email,
        userHashHmac: decodedData.user_hash,
        isUserDataLoaded: true,
      });
    } else {
      setAuthState({ ...authState, analyticsId: getAnalyticsCookie(), isUserDataLoaded: true });
    }
  };

  React.useEffect(() => {
    checkUserCookies();
  }, []);

  return { authState };
};

export const AuthContainer = createContainer(useAuthContext);
