import React from 'react';

export const paddingSizes = [
  0, 10, 15, 20, 24, 28, 32, 40, 48, 60, 70, 80, 90, 120, 150, 170,
] as const;
export type Size = typeof paddingSizes[number];

export type PaddingSize =
  | Size
  | {
      x?: Size;
      y?: Size;
      top?: Size;
      bottom?: Size;
      left?: Size;
      right?: Size;
    };

export const PaddingContext = React.createContext<PaddingSize>({
  top: 32,
  x: 48,
  bottom: 48,
});

export const usePaddingContext = () => React.useContext(PaddingContext);
