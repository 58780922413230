import React from 'react';

import type { IHideableMobileSection } from '@components/HideableMobileSection/HideableMobileSection';
import { HideableMobileSection as HideableMobileSectionComponent } from '@components/HideableMobileSection/HideableMobileSection';
import { useMeasure } from '@hooks/useMeasure';

interface IProps extends IHideableMobileSection {
  children: React.ReactNode;
}

export const HideableMobileSection: React.FC<IProps> = ({
  title,
  subtitle,
  keepHeaderOnDesktop,
  className,
  children,
  theme,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [bind, { height }] = useMeasure();

  return (
    <HideableMobileSectionComponent
      className={className}
      height={height}
      isOpen={isOpen}
      keepHeaderOnDesktop={keepHeaderOnDesktop}
      subtitle={subtitle}
      theme={theme}
      title={title}
      onToggleOpen={() => setIsOpen(!isOpen)}
    >
      <div {...bind}>{children}</div>
    </HideableMobileSectionComponent>
  );
};
