import React from 'react';

import { Footer } from '@components/Footer/Footer';
import { Nav } from '@containers/Nav/Nav';

import styles from './Layout.module.scss';

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => (
  <div className={styles.layout}>
    <Nav />
    <div className={styles.content}>{children}</div>
    <Footer />
  </div>
);
