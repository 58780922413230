import React from 'react';

import { routes } from '@routes';

import GlobeIcon from '@icons/tiny/globe.svg';
import GroupIcon from '@icons/tiny/group.svg';
import PhoneIcon from '@icons/tiny/phone.svg';
import PinIcon from '@icons/tiny/pin.svg';
import PlayerIcon from '@icons/tiny/player.svg';
import ShieldIcon from '@icons/tiny/shield.svg';

interface ILink {
  name: string;
  path: string;
  subpages?: string[];
  external?: boolean;
}

interface ISubLink extends ILink {
  icon: JSX.Element;
  description: string;
}

export interface IMainLink extends ILink {
  submenu?: ISubLink[];
  mainPath?: string;
}

export const links: IMainLink[] = [
  {
    name: 'Product',
    path: routes.features,
    submenu: [
      {
        icon: <GlobeIcon />,
        name: 'CDN Features',
        description: 'The core of CDN77',
        path: routes.features,
      },
      {
        icon: <PlayerIcon />,
        name: 'Video delivery',
        description: 'For VOD and live video platforms',
        path: routes.videoCdn,
      },
      {
        icon: <ShieldIcon />,
        name: 'Security',
        description: 'Security features included in all plans',
        path: routes.security,
      },
    ],
  },
  { name: 'Network', path: routes.network },
  {
    name: 'Pricing',
    path: routes.pricing,
  },
  {
    name: 'Company',
    path: routes.aboutUs,
    submenu: [
      {
        icon: <PinIcon />,
        name: 'About CDN77',
        description: 'Find out more about our company and values that drive us',
        path: routes.aboutUs,
      },
      {
        icon: <GroupIcon />,
        name: 'Contact',
        description: 'Get in touch with our tech support or Client Solutions team',
        path: routes.contact,
      },
      {
        icon: <PhoneIcon />,
        name: 'Meet our clients',
        description: 'What clients are saying about us',
        path: routes.reviews,
      },
    ],
  },
];
