import { useRouter } from 'next/router';
import React from 'react';

import { useInitialDataQuery } from '@hooks/api/useInitialDataQuery';
import { AuthContainer } from '@hooks/authContainer';

interface IUser {
  ipAddress: string;
  analyticsId?: string;
  userId?: string;
}

export const AnalyticsTracking: React.FC = () => {
  const router = useRouter();
  const [ip, setIp] = React.useState<string | null>(null);

  const initialDataQuery = useInitialDataQuery();

  const { authState } = AuthContainer.useContainer();
  const [userTrackingSubmitted, setUserTrackingSubmitted] = React.useState(false);

  const setAndSubmitDimension = (user: IUser) => {
    if (userTrackingSubmitted) return;

    window.dataLayer.push({ user });
    setUserTrackingSubmitted(true);
  };

  React.useEffect(() => {
    // track pageview
    if (userTrackingSubmitted) {
      window.dataLayer.push({
        event: 'ga.pageview',
        page: {
          path: router ? router.asPath : '',
        },
      });
    }
  }, [router, userTrackingSubmitted]);

  React.useEffect(() => {
    if (ip === null && initialDataQuery.data && 'ip' in initialDataQuery.data) {
      setIp(initialDataQuery.data.ip);
    }
  }, [ip, initialDataQuery.data]);

  React.useEffect(() => {
    if (ip && authState.isUserDataLoaded) {
      let user: IUser = { ipAddress: ip };

      if (authState.analyticsId) {
        user = { ...user, analyticsId: authState.analyticsId };
      }
      if (authState.id) {
        user = { ...user, userId: authState.id.toString() };
      }

      setAndSubmitDimension(user);
    }
  }, [ip, authState.isUserDataLoaded]);

  return null;
};
