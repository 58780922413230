import classNames from 'classnames/bind';
import React from 'react';

import styles from './Hamburger.module.scss';

export interface IProps {
  isActive: boolean;
  onToggleMenu: () => void;
}

const cx = classNames.bind(styles);

export const Hamburger: React.FC<IProps> = ({ isActive, onToggleMenu }) => (
  <button
    aria-label="Menu"
    className={cx('hamburger', { isActive })}
    data-testid="menu-button"
    type="button"
    onClick={onToggleMenu}
  >
    <span className={styles.hamburgerBox}>
      <span className={styles.hamburgerInner} />
    </span>
  </button>
);
