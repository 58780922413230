import React from 'react';

import { AuthContainer } from '@hooks/authContainer';

interface IProps {
  children: React.ReactNode;
}

export const ContextProvider: React.FC<IProps> = ({ children }) => (
  <AuthContainer.Provider>{children}</AuthContainer.Provider>
);
