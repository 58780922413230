const SESSION_STORAGE_KEY = 'last-reload';

// allow reloads when the timestamp is not valid, or if 30 seconds passed from the last reload
const TIME_BETWEEN_RELOADS = 30_000;

const loadLastReloadTime = () => {
  const lastRefreshTime = sessionStorage.getItem(SESSION_STORAGE_KEY);

  if (lastRefreshTime) {
    return new Date(Number(lastRefreshTime));
  }

  return null;
};

const updateReloadTime = () => {
  sessionStorage.setItem(SESSION_STORAGE_KEY, new Date().valueOf().toString());
};

const getSecondsDiff = (current: Date, previous: Date) =>
  (current.getTime() - previous.getTime()) / 1000;

export const handleMalformedResponse = (headers: Headers, error: Error) => {
  // Invalid malformed response - probably got a HTML error message or HTML captcha instead of JSON
  if (
    headers.get('content-type')?.includes('text/html') ||
    error.message.startsWith("Unexpected token '<'")
  ) {
    const lastReloadTime = loadLastReloadTime();

    const secondsDiff = lastReloadTime ? getSecondsDiff(new Date(), lastReloadTime) : null;

    // check seconds diff to prevent reload loop
    if (secondsDiff === null || secondsDiff > TIME_BETWEEN_RELOADS) {
      window.location.reload();
      updateReloadTime();
    }

    throw new Error('Captcha required. Please refresh the page.');
  }
};
