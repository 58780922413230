import * as Sentry from '@sentry/nextjs';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import React from 'react';

import { parseApiResponse } from 'lib/parseApiResponse';

interface IUseQueryParams {
  url: null | string;
  body?: string | Record<string, unknown> | null;
  method?: 'GET' | 'POST';
  enabled?: boolean;
}

export const useQuery = <TData>({ url, body, method = 'GET', enabled = true }: IUseQueryParams) => {
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);

  const { data, error, isLoading, isRefetching, isSuccess, status, refetch } = useReactQuery<
    TData,
    any
  >(
    body ? [url, body] : [url],
    () => {
      const controller = new AbortController();
      const { signal } = controller;

      const promise = fetch(process.env.NEXT_PUBLIC_PROXY_URL ?? '', {
        method: 'POST',
        cache: 'no-cache',
        redirect: 'follow',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          method,
          path: url,
          body: body ?? undefined,
        }),
        signal,
      })
        .then(async response => {
          if (response.status >= 500) {
            throw new Error('Server error');
          }

          const data = await parseApiResponse<{
            status?: 'error' | number;
            title?: string;
            description?: string;
            error?: string;
            errors?: string[];
          }>(response);

          return { status: response.status, data };
        })
        .then(({ data }) => {
          if ('errors' in data || 'error' in data) {
            if (data.errors && Array.isArray(data.errors)) {
              throw new Error(data.errors[0]);
            } else if (data.error) {
              throw new Error(data.error);
            } else {
              throw new Error('Unknown server error');
            }
          }

          return data as TData;
        });

      // Cancel the request if React Query calls the `promise.cancel` method
      (promise as any).cancel = () => controller.abort();

      return promise;
    },
    {
      enabled: enabled && url !== null,
      refetchOnWindowFocus: false,
    },
  );

  React.useEffect(() => {
    const isCancelledError = error && 'silent' in error;
    if (error && !isCancelledError) {
      Sentry.captureException(error);
    }
  }, [error]);

  React.useEffect(() => {
    if ((status === 'success' || status === 'error') && isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [status]);

  return {
    data,
    error: error && 'silent' in error ? null : error,
    isLoading: url === null || isLoading || isInitialLoad,
    isRefetching,
    isSuccess,
    refetch,
  };
};
