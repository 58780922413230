import React from 'react';

export const useScrollLock = () => {
  const [scrolledY, setScrolledY] = React.useState(0);

  const enableScroll = () => {
    document.body.removeAttribute('style');
    window.scrollBy(0, scrolledY);
  };

  const disableScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    setScrolledY(top);
    document.body.setAttribute('style', `overflow:hidden;position:fixed;top:-${top}px`);
  };

  return { enableScroll, disableScroll };
};
