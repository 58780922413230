import React from 'react';

import useEventListener from './useEventListener';

const breakpointSize = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const useWindowSize = (targetBreakpoint?: keyof typeof breakpointSize) => {
  const isLargerThanBreakpoint = () =>
    window.innerWidth >= breakpointSize[targetBreakpoint ?? 'xs'];

  const [windowSize, setWindowSize] = React.useState<{
    width?: number;
    height?: number;
    isLargerThanBreakpoint: boolean;
  }>({
    width: undefined,
    height: undefined,
    isLargerThanBreakpoint: false,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isLargerThanBreakpoint: isLargerThanBreakpoint(),
    });
  };

  useEventListener('resize', handleResize);

  React.useEffect(() => {
    handleResize();
  }, []);

  return windowSize;
};
