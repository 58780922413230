import classNames from 'classnames/bind';
import React from 'react';

import type { ILayoutGap } from '@components/Layout/Row/Row';

import styles from './Col.module.scss';

export type IColSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface IProps {
  className?: string;
  tagName?: 'div' | 'span' | 'li';
  gap?: ILayoutGap;
  gapX?: ILayoutGap;
  gapY?: ILayoutGap;
  xs?: IColSizes | 'auto';
  sm?: IColSizes | 'auto';
  md?: IColSizes | 'auto';
  lg?: IColSizes | 'auto';
  xl?: IColSizes | 'auto';
  testId?: string;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Col: React.FC<IProps> = ({
  className,
  tagName: TagName = 'div',
  gap,
  gapX = gap ? undefined : 'md',
  gapY = gap ? undefined : 'md',
  xs,
  sm,
  md,
  lg,
  xl,
  testId,
  children,
}) => (
  <TagName
    className={cx('col', className, {
      [`gap-${gap}`]: gap,
      [`x-${gapX}`]: gapX,
      [`y-${gapY}`]: gapY,
      [`col-xs-${xs}`]: xs,
      [`col-sm-${sm}`]: sm,
      [`col-md-${md}`]: md,
      [`col-lg-${lg}`]: lg,
      [`col-xl-${xl}`]: xl,
    })}
    data-testid={testId}
  >
    {children}
  </TagName>
);
