/* eslint-disable react/destructuring-assignment */
import classNames from 'classnames/bind';
import React from 'react';

import type { ILayoutAlign } from '@components/Layout/Row/Row';
import { Link } from '@components/Link/Link';
import type { IIconProperty } from '@components/TextWithIcon/TextWithIcon';
import { TextWithIcon } from '@components/TextWithIcon/TextWithIcon';

import styles from './Button.module.scss';
import ArrowLeftIcon from '@icons/arrowLeft.svg';
import ArrowRightIcon from '@icons/arrowRight.svg';
import ExternalLinkIcon from '@icons/externalLink.svg';

export type IButtonSize = 'md' | 'lg' | 'xl';

interface IBaseProps extends IIconProperty {
  theme?: 'button' | 'link';
  icon?: JSX.Element;
  variant?: 'primary' | 'secondary' | 'inverted' | 'inherit';
  isProcessing?: boolean;
  processingText?: string;
  className?: string;
  external?: boolean;
  title?: string;
  size?: IButtonSize;
  noIcon?: boolean;
  withArrow?: boolean;
  horizontalAlign?: ILayoutAlign;
  verticalAlign?: ILayoutAlign;
  block?: boolean;
  isFakeButton?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IButtonProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'color'> {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
}

interface ILinkProps extends Omit<React.HTMLAttributes<HTMLAnchorElement>, 'color'> {
  href: string;
  activeClassName?: string;
  isActive?: boolean;
  download?: boolean;
  rel?: string;
  scrollToTop?: boolean;
}

type IChildrenProps =
  | { children: string; testId?: string }
  | {
      children:
        | JSX.Element
        | [JSX.Element, JSX.Element]
        | [string, JSX.Element]
        | [JSX.Element, string];
      testId: string;
    };

export type IProps = ((IButtonProps & IBaseProps) | (ILinkProps & IBaseProps)) & IChildrenProps;

const isLink = (props: IProps): props is ILinkProps & IBaseProps & IChildrenProps =>
  (props as ILinkProps & IBaseProps).href !== undefined;

const scrollToElementId = (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();

  if (e.target) {
    const { href } = e.target as HTMLAnchorElement;

    if (href) {
      const targetElement = document.getElementById(href.split('#')[1]);
      targetElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
};

const cx = classNames.bind(styles);

export const Button: React.FC<IProps> = props => {
  const {
    id,
    testId,
    title,
    theme,
    variant,
    block,
    icon,
    external = false,
    withArrow = false,
    size,
    iconPosition,
    noIcon,
    children,
    horizontalAlign,
    iconSize,
    spacing,
    verticalAlign,
    processingText,
    isProcessing,
    isFakeButton,
    className,
  } = props;

  const getContent = () => {
    if ((icon !== undefined || withArrow || external) && !noIcon && children) {
      const getIcon = () => {
        if (external && !icon) {
          return <ExternalLinkIcon />;
        }
        if (withArrow) {
          return iconPosition === 'before' ? <ArrowLeftIcon /> : <ArrowRightIcon />;
        }
        return icon ?? null;
      };

      const buttonIcon = getIcon();

      if (buttonIcon && !Array.isArray(children)) {
        return (
          <TextWithIcon
            horizontalAlign={horizontalAlign}
            icon={buttonIcon}
            iconPosition={iconPosition}
            iconSize={iconSize ?? 12}
            spacing={spacing ?? 'sm'}
            verticalAlign={verticalAlign}
            noEvent
          >
            {children}
          </TextWithIcon>
        );
      }
    }

    return children ?? undefined;
  };

  const content = getContent();

  if (isFakeButton) {
    return (
      <span
        className={cx(
          theme ?? 'link',
          variant ?? 'primary',
          size ?? 'lg',
          iconPosition,
          className,
          {
            isProcessing,
            external,
            block,
            withIcon: icon !== undefined || withArrow || (external && !noIcon),
          },
        )}
      >
        {content}
      </span>
    );
  }

  return isLink(props) ? (
    <Link
      activeClassName={props.activeClassName}
      className={cx(theme ?? 'link', variant ?? 'primary', size ?? 'lg', iconPosition, className, {
        isProcessing,
        external,
        block,
        withIcon: icon !== undefined || withArrow || (external && !noIcon),
      })}
      download={props.download}
      external={external}
      href={props.href}
      id={id}
      isActive={props.isActive}
      rel={props.rel}
      scrollToTop={props.scrollToTop}
      testId={testId ?? (typeof children === 'string' ? children : '')}
      title={title}
      onBlur={props.onBlur}
      onClick={e => {
        if (props.href.startsWith('#')) {
          scrollToElementId(e);
        }

        if (props.onClick) {
          props.onClick(e);
        }
      }}
      onFocus={props.onFocus}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {isProcessing ? processingText ?? 'Processing' : content}
    </Link>
  ) : (
    <button
      className={cx(theme ?? 'button', size ?? 'lg', variant ?? 'primary', className, {
        block,
        isProcessing,
        withArrow,
        withIcon: icon,
      })}
      data-testid={children}
      disabled={props.disabled ?? isProcessing}
      id={id}
      title={title}
      type={props.type ?? 'button'}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {isProcessing ? processingText ?? 'Processing' : content}
    </button>
  );
};
