import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';
import { AuthContainer } from '@hooks/authContainer';
import { externalRoutes, routes } from '@routes';

import styles from './SideLinks.module.scss';
import ArrowRightIcon from '@icons/arrowRight.svg';

interface IProps {
  onCloseMenu: () => void;
}

const cx = classNames.bind(styles);

export const SideLinks: React.FC<IProps> = ({ onCloseMenu }) => {
  const { authState } = AuthContainer.useContainer();

  return (
    <div className={cx('wrapper', { isVisible: authState.isUserDataLoaded })}>
      {authState.id ? (
        <Button
          className={styles.button}
          href={externalRoutes.client.dashboard}
          icon={<ArrowRightIcon />}
          size="md"
          theme="button"
          verticalAlign="center"
          onClick={onCloseMenu}
        >
          Client Panel
        </Button>
      ) : (
        <>
          <Button className={styles.link} href={externalRoutes.client.login} external noIcon>
            Login
          </Button>
          <Button
            className={styles.button}
            href={routes.signup}
            id="sign-up"
            size="md"
            theme="button"
            onClick={onCloseMenu}
          >
            Get started
          </Button>
        </>
      )}
    </div>
  );
};
