interface IGaEventArguments {
  label?: string;
  action: string;
  category?: string;
  type?: 'button' | 'link';
}

interface IGaEventRecord {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
}

const gaSubmitFormEvent = ({
  label,
  action,
  category = 'form submission',
}: IGaEventArguments): IGaEventRecord => ({
  event: 'gaEvent',
  eventCategory: category,
  eventAction: action,
  eventLabel: label,
});

const gaClickEvent = ({ label, action, type = 'button' }: IGaEventArguments): IGaEventRecord => ({
  event: 'gaEvent',
  eventCategory: `${type} click`,
  eventAction: action,
  eventLabel: label,
});

const isEqual = (a: any, b: any): boolean => {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length === 0 && aProps.length !== bProps.length) return false;

  return aProps.every(prop => a[prop] === b[prop]);
};

const addGaEvent = (a: any) => {
  if (typeof window !== 'object') return;

  const isEventExist = window?.dataLayer.some(event => isEqual(a, event));

  if (!isEventExist) {
    window.dataLayer.push(a);
  }
};

export const trackContactFormSubmit = (formId: string, subject: string) => {
  addGaEvent(
    gaSubmitFormEvent({
      action: formId,
      label: subject,
    }),
  );
};

export const trackSignupFormSubmit = () => {
  addGaEvent(gaSubmitFormEvent({ action: 'signup', label: 'Signup Submission - account created' }));
};

export const trackClickEvent = (label: string, action: string, type?: 'button' | 'link') => {
  addGaEvent(gaClickEvent({ label, action, type }));
};
